import { CloseOutlined } from "@ant-design/icons"
import Comments from "@components/PostModal/components/Comments"
import MainCommentForm from "@components/PostModal/components/MainCommentForm"
import { Divider } from "antd"
import React, { useEffect } from "react"

const PostComment = ({
  handleCloseModal,
  getPost,
  item,
  comments,
  onComment,
  setComments,
  onDeleteComment,
  onDeleteReply,
  isEssay,
  isFavorite,
  getEssayPhotoVideoComments,
  setTotalComments,
}) => {
  useEffect(() => {
    const getComments = async () => {
      const {
        data: [post],
      } = await getPost({ postId: item.id })

      if (post) {
        setComments(post.comments)
      }
    }

    const getEssayFavoritesComments = async () => {
      const commentsData = await getEssayPhotoVideoComments(item.photoVideoId)

      setComments(commentsData)
      setTotalComments(
        commentsData.reduce(
          (acc, cur) =>
            acc + 1 + cur.replys.reduce((replyAcc) => replyAcc + 1, 0),
          0
        )
      )
    }

    !isEssay && getComments()

    if (isFavorite) getEssayFavoritesComments()
  }, [])

  if (!item) return null

  return (
    <>
      <div className="overflow-hidden modalContent w-100">
        <Comments
          {...item}
          comments={comments}
          onFinish={onComment}
          onDeleteComment={onDeleteComment}
          onDeleteReply={onDeleteReply}
          formName="textComment"
          isEssay={isEssay}
        />
      </div>

      <div className="flex items-center w-100 ph16 pv12 aaaa">
        <MainCommentForm
          placeholder="Comentar"
          formName="textComment"
          onFinish={onComment}
          size={32}
        />
        <button
          className="input-reset bg-white bn pa0"
          onClick={handleCloseModal}
        >
          <CloseOutlined />
        </button>
      </div>
      <Divider style={{ margin: "0px" }} />
    </>
  )
}

export default PostComment
