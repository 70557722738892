import Gallery from "@components/Gallery"
import { Redirect } from "@reach/router"
import queryString from "query-string"
import React, { useEffect, useState } from "react"

const DetailsPage = ({ location }) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  const parsedQueryString = queryString.parse(location.search)
  const essayId = parsedQueryString && parsedQueryString.searchId

  if (essayId) {
    return (
      <>
        <div className="essay__page page">
          <Gallery essayId={essayId} />
        </div>
      </>
    )
  }

  return <Redirect to="/pagina-nao-encontrada" noThrow />
}

export default DetailsPage
